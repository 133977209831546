.card
  width: 40%
  aspect-ratio: 1/1
  padding: 30px
  border: 1px solid rgba(139, 139, 139, 0.8)
  border-radius: 50%
  color: #fff
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  background-size: cover
  background-repeat: no-repeat
  background-position: center
  transition: .3s
  box-shadow: 0px 0px 20px 2px rgba(5, 5, 5, 0.8)
  &:hover .title
    transform: scale(1.05)
  // @media screen and (max-width: 1300px)
  //   height: 150px
  // @media screen and (max-width: 425px)
  //   height: 150px
  // @media screen and (max-width: 365px)
  //   height: 150px
  // @media screen and (max-width: 315px)
  //   height: 150px

.title
  text-align: center
  transition: .3s
  // transform: translateY(-20px)
  font-size: 32px
  margin: 8px
  cursor: default
  @media screen and (max-width: 900px)
    font-size: 16px
  //white-space: nowrap

.text
  color: #ffffff
  font-size: 14px
  font-weight: 700
  background: rgba(217, 159, 36, 0.9)
  border-radius: 4px
  padding: 4px 8px
  @media screen and (max-width: 500px)
    font-size: 16px

