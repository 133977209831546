.list
  display: flex
  overflow-y: scroll
  padding: 10px 0

.listItem
  background: #d4d4d4
  padding: 4px 10px
  border-radius: 6px
  margin: 0 10px 0 0
  cursor: pointer
  text-wrap: nowrap
  border: 1px solid #d4d4d4
  transition: border .3s

.active
  border: 1px solid black
