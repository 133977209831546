.section
  position: fixed
  top: 0
  left: 0
  width: 100vw
  height: 100vh
  display: flex
  justify-content: center
  align-items: center
  visibility: hidden
  opacity: 0
  transition: .3s
  &_active
    visibility: visible
    opacity: 1

.overlay
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  background: rgba(0, 0, 0, 0.7)
  backdrop-filter: blur(10px)

.wrapper
  position: relative
  z-index: 2
  width: 600px
  //height: 400px
  background: #fff
  border-radius: 10px
  padding: 40px 20px
  display: flex
  flex-direction: column
  //justify-content: center
  align-items: center
  @media screen and (max-width: 600px)
    width: 100%

.title
  text-align: center
  margin: 0 0 20px

.text
  text-align: center
  margin: 10px
  &:last-child
    margin: 0

.form
  width: 300px
  margin: 40px 0 0
  display: flex
  flex-direction: column
  align-items: center
  input
    width: 100%
    padding: 10px
    border: 2px solid #479e53
    border-radius: 10px
    margin: 0 0 10px
    @media screen and (max-width: 340px)
      width: 80%
    &:last-child
      margin: 0
  button
    border: 2px solid #479e53
    border-radius: 10px
    width: 100%
    padding: 10px
    background: #479e53
    color: #fff
    cursor: pointer
    transition: .3s
    &:hover
      color: #479e53
      background: #fff
    @media screen and (max-width: 340px)
      width: 80%




