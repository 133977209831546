.section
  padding: 100px 0
  position: fixed
  z-index: 2
  top: -120%
  left: 0
  width: 100vw
  height: 100vh
  background: rgba(255, 255, 255, 0.5)
  backdrop-filter: blur(14px)
  transition: .3s
  &_active
    top: 0

.wrapper
  padding: 10px 20px
  width: 100%
  height: 80%
  //background: #000

.list

.listItem
  margin: 0 0 20px
.navLink
  font-size: 20px
  font-weight: 600
  text-decoration: none
  color: #000
