.header
  position: relative
  width: 100vw
  //height: 80vh
  //@media screen and (max-width: 768px)
  //  height: 100vh

.navBar
  position: fixed
  top: 0
  left: 0
  width: 100%
  z-index: 3
  background: #479e53
  color: #dafffd
  box-shadow: 0 5px 5px -5px rgba(0, 0, 0, .5)
  display: flex

.navBarWrap
  padding: 0 20px
  width: 100%
  display: flex
  justify-content: space-between
  align-items: center

.sliderContainer
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  //background: rgba(0, 0, 0, 0.3)

.sliderContainerRed
  position: absolute
  top: 0
  height: 0
  width: 100vw
  height: 100%
  background: red

.sliderImage
  position: absolute
  width: 100%
  height: 100%
  max-width: 100vw
  object-fit: cover

.next,
.prev
  top: calc(50% - 20px)
  position: absolute
  background: white
  border-radius: 30px
  width: 40px
  height: 40px
  display: flex
  justify-content: center
  align-items: center
  user-select: none
  cursor: pointer
  font-weight: bold
  font-size: 18px
  z-index: 2


.next
  right: 10px

.prev
  left: 10px
  transform: scale(-1)
