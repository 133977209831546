.card
  position: relative
  padding: 16px 18px
  background: #fff
  border-radius: 6px

.title
  font-size: 20px
  font-weight: 700
  margin: 0 0 12px
  @media screen and (max-width: 850px)
    font-size: 16px
.date
  color: #7a7a7a
  margin: 0 0 6px

.text

.rating
  position: absolute
  top: 16px
  right: 18px
