.fixPricePopup
  width: 499px
  //height: 500px
  position: fixed
  right: -120%
  bottom: 50px
  background: #479e53
  border-radius: 10px
  //overflow: hidden
  padding: 30px
  transition: .3s
  visibility: hidden
  z-index: 2
  opacity: 0
  &_active
    right: 30px
    bottom: 50px
    visibility: visible
    opacity: 1
    @media screen and (max-width: 425px)
      padding: 20px 10px 10px
      overflow: scroll
      width: 100%
      height: calc( 100vh - 200px )
      right: 0
      bottom: 68px


.title
  color: #fff
  font-size: 20px
  font-weight: 700
  line-height: 24px
  margin-bottom: 10px
  text-align: center

.phones
  padding: 0 0 14px
  display: flex
  justify-content: space-between
  align-items: center

.phone
  color: #ff9d02
  font-size: 15px
  font-weight: 600
  text-decoration: none
  &:hover
    text-decoration: underline

.table
  width: 100%
  border-radius: 8px
  border-collapse: collapse
  tbody
    tr
      th
        &:last-child
          display: flex
          justify-content: start

  thead
    background: #000
    font-size: 14px
    th
      color: #fff
      overflow: hidden
      background: #717376
      font-weight: 600
      &:first-child
        border-radius: 4px 0 0
      &:last-child
        border-radius: 0 4px 0 0
        display: flex
        justify-content: start
  tr
    background: #404143
    &:nth-child(2n)
      background: #717376
    &:last-child
      overflow: hidden
  th
    color: #fff
    font-size: 12px
    font-weight: 300
    text-align: left
    padding: 6px 14px 6px 14px
    &:first-child
      border-radius: 0 0 0 4px
    &:last-child
      display: flex
      align-items: center
      border-radius: 0 0 4px 0

.buttonPrice
  cursor: pointer
  margin: 18px 0 0
  width: 100%
  padding: 8px 10px
  background: rgba(217, 159, 36,0.5)
  outline: none
  border: none
  border-radius: 4px
  color: #fff
  font-weight: 600
  transition: .3s
  box-shadow: 2px 2px 6px rgba(0,0,0,0.2)
  text-transform: uppercase
  &:hover
    transition: .3s
    background: rgba(217, 159, 36, 0.2)

.closeLine
  position: absolute
  top: 0px
  right: 0
  display: none
  @media screen and (max-width: 425px)
    display: block
.icon
  width: 30px
  height: 30px
  border: 1px solid #ff0000
  border-radius: 3px
  box-shadow: 2px 2px 4px rgba(0,0,0,0.2)
  &:hover
    opacity: .9

.closeIcon
  position: absolute
  top: 4px
  right: 4px
  cursor: pointer
  transition: .2s
  
  &:hover
    opacity: .8
  @media screen and (max-width: 425px)
    display: none
    top: 10px
    right: 0
    z-index: 999