.title
  margin: 0 0 18px
  font-size: 28px
  color: #d99f24
  text-align: center
  text-transform: uppercase
  &_text_white
    color: #fff
  @media screen and (max-width: 495px)
    font-size: 22px

.divider
  margin: 0 auto 20px
  width: 100px
  border-bottom: 2px solid #479e53
  &_color_white
    border-bottom: 2px solid #fff
