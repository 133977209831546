.home
  position: relative
  // height: 900px
  padding: 100px 0 20px
  display: flex
  justify-content: center
  align-items: center

.background
  position: absolute
  top: 0
  left: 0
  background-image: url('../images/main-bg/1.jpg')
  background-repeat: no-repeat
  background-size: cover
  // border-radius: 0 0 400px
  filter: brightness(.5)
  width: 100%
  height: 100%
  overflow: hidden
  // transform: skewY(3deg)
  transform-origin: right top 0
  transition: .3s
  z-index: -1
  // @media screen and (max-width: 1440px)
  //   border-radius: 0 0 250px
  // @media screen and (max-width: 1199px)
  //   border-radius: 0 0 200px
  // @media screen and (max-width: 991px)
  //   border-radius: 0 0 150px
  // @media screen and (max-width: 580px)
  //   border-radius: 0 0 50px

.bigTitle
  font-size: 52px
  font-weight: 700
  color: #fff
  margin: 0 0 36px
  @media screen and (max-width: 768px)
    margin: 24px 0 36px
    text-align: center
    font-size: 42px
  @media screen and (max-width: 500px)
    font-size: 33px
    margin: 24px 0 16px

.title
  font-size: 47px
  font-weight: 700
  color: #fff
  margin: 0 0 36px
  @media screen and (max-width: 768px)
    margin: 24px 0 36px
    text-align: center
    font-size: 37px
  @media screen and (max-width: 500px)
    font-size: 28px
    margin: 24px 0 16px

.info
  display: grid
  grid-gap: 10px
  grid-template-columns: 55% 45%
  @media screen and (max-width: 1300px)
    grid-gap: 20px
    grid-template-columns: repeat(1, 1fr)

.infoText
  font-size: 28px
  line-height: 32px
  color: #fff
  margin: 0 0 16px
  @media screen and (max-width: 768px)
    text-align: center
    font-size: 22px
    margin: 0 0 20px
  @media screen and (max-width: 500px)
    margin: 0 0 6px
    font-size: 14px

// .iconList 
//   display: flex
.infoLink
  color: #ffb96a
  text-decoration: none
  font-weight: 700


.infoPrice
  padding: 4px 8px
  background: rgba(37, 196, 64, 0.5)
  border-radius: 6px
  font-weight: 700
  white-space: nowrap

.button
  button
  text-transform: uppercase
  @media screen and (max-width: 768px)
    text-align: center
    
