* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Montserrat', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
}

.container {
  margin: 0 auto;
  position: relative;
  padding: 0 80px;
  width: 100%;
  max-width: 1920px;
  /*max-width: 1480px;*/
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

@media screen and (max-width: 990px) {
  .container {
    padding: 0 40px;
  }
}

@media screen and (max-width: 590px) {
  .container {
    padding: 0 20px;
  }
}

@media screen and (max-width: 425px) {
  .container {
    padding: 0 10px;
  }
}