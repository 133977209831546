.card
  @media screen and (max-width: 520px)
    text-align: center
  //height: 300px
  //background: #000

.iconContainer
  width: 70px
  height: 70px
  border: 1px solid #ff9d01
  border-radius: 50%
  display: flex
  justify-content: center
  align-items: center
  @media screen and (max-width: 520px)
    text-align: center
    margin: 0 auto
    //vertical-align: center

.title
  margin: 16px 0 8px
  font-weight: 700
  color: #479e53

.text
  margin: 0 0 16px
