.icon
  width: 30px
  height: 30px
  border: 1px solid #ff0000
  border-radius: 3px
  &:hover
    opacity: .9
.section
  z-index: 2
  position: fixed
  top: 0
  left: 0
  width: 100vw
  height: 100vh
  display: flex
  justify-content: center
  align-items: center
  visibility: hidden
  opacity: 0
  transition: .3s
  &_active
    visibility: visible
    opacity: 1

.overlay
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  background: rgba(0, 0, 0, 0.7)
  backdrop-filter: blur(10px)

.wrapper
  position: relative
  z-index: 2
  width: 500px
  height: 500px
  background: rgba(70, 158, 83, 0.2)
  border: 1px solid rgb(70, 158, 83)
  border-radius: 10px
  padding: 40px 20px
  display: flex
  flex-direction: column
  box-shadow: 2px 2px 6px rgba(0,0,0,0.2)
  align-items: center
  @media screen and (max-width: 600px)
    width: 100%

.title
  margin: 70px 0 40px
  width: 80%
  text-align: center
  font-size: 24px
  color: #ffffff
  span
    color: #ff0000
  @media screen and (max-width: 500px)
    margin: 80px 0 20px
    font-size: 20px
  @media screen and (max-width: 380px)
    margin: 100px 0 20px
    font-size: 16px

.form
  width: 80%
  display: flex
  flex-direction: column
  input
    padding: 10px
    border: 2px solid #fff
    border-radius: 10px
    background: transparent
    margin: 0 0 20px
    &:last-child
      margin: 0
    &::placeholder
      color: #fff
  button
    padding: 10px
    border: 2px solid #fff
    color: #fff
    border-radius: 10px
    background: rgb(70, 158, 83)
    cursor: pointer

.closeBtn
  cursor: pointer
  position: absolute
  top: 6px
  right: 6px
  transition: .2s
  
  &:hover
    opacity: .9
