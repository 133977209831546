.icon
  background: rgba(205, 246, 238, 0.5)
  border-radius: 8px
  padding: 5px
  box-shadow: 2px 1px 6px rgba(1, 20, 17, 0.5)

.list
  
  height: 100%
  display: flex
  flex-direction: row
  justify-content: start
  align-items: center
  justify-content: center
  gap: 10px
  padding-top: 20px
  @media screen and (max-width: 1300px)
    flex-direction: row
    justify-content: center
    flex-wrap: wrap
  div
    display: flex
    justify-content: center
    align-items: center
    width: 40px
    height: 40px
    border-radius: 50%
    margin: 4px
    a
      display: flex
      justify-content: center
      align-items: center
      svg
        filter: drop-shadow(1px 1px 5px #9b9b9b)
  @media screen and (max-width: 1300px)
    margin: 0 14px 0 0
    &:last-child
      margin: 0
    @media screen and (max-width: 425px)
      margin: 0 8px 0 0
