.section
  padding: 80px 0 0


.wrapper
  padding: 40px 0
  box-shadow: 0 0 30px 10px rgba(0, 0, 0, 0.2)
  border-radius: 10px
  background: #fff
  transform: translateY(67px)
  @media screen and (max-width: 780px)
    transform: translateY(57px)
    li
      img
        width: 100px
  @media screen and (max-width: 380px)
    li
      img
        width: 80px
