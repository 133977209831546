.card
 
  position: relative
  color: #fff
  border: 2px solid rgba(255, 255, 255, .9)
  border-radius: 16px
  //padding: 26px 10px
  width: 100%
  min-height: 250px
  //display: flex
  //flex-direction: column
  //justify-content: flex-start
  //align-items: center
  background-color: rgba(0, 0, 0, 0.3)
  background-position: center
  background-size: cover
  overflow: hidden
  box-shadow: 5px 5px 30px 7px rgba(0, 0, 0, 0.29)
  transition: .3s
  &:hover
    transform: scale(1.05)
    z-index: 0
  @media screen and (max-width: 1640px)
    min-height: 280px
  @media screen and (max-width: 940px)
    min-height: 260px
  @media screen and (max-width: 785px)
    min-height: 270px

.overlay
  padding: 26px 10px
  display: flex
  flex-direction: column
  justify-content: flex-start
  align-items: center
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  background: rgba(0, 0, 0, 0.5)

.title
  text-align: center
  font-size: 28px
  font-weight: 700
  margin: 0 0 20px

.list
  div
    display: flex
    justify-content: center
    align-items: center
    margin: 0 10px 0 0
    width: 16px
    height: 16px
    background-color: #fff
    border-radius: 50%

  li
    display: flex
    align-items: center
    line-height: 23px

.listItem