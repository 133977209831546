.toast
  position: fixed
  z-index: 10
  top: 100px
  right: -110%
  width: 300px
  height: 80px
  padding: 10px
  border-radius: 10px
  background: rgba(0, 0, 0, 0.7)
  display: flex
  justify-content: center
  align-items: center
  transition: .3s
  &_active
    right: 20px

.title
  color: #fff
