.button
  background: transparent
  border-radius: 10px
  cursor: pointer
  font-size: 16px
  font-weight: 700
  outline: none
  overflow: hidden
  padding: 10px 40px
  position: relative
  border: 2px solid rgba(37, 196, 64, 0.5)
  color: #ffb96a
  transition: all .3s linear
  text-transform: uppercase
  &:after
    background: rgba(37, 196, 64, 0.5)
    bottom: 0
    content: ""
    height: 100%
    left: -10%
    position: absolute
    transform: skewx(-45deg)
    transition: .5s
    width: 17%
    z-index: -1
  &:hover
    color: #fff
    &:after
      left: -10%
      width: 120%
  @media screen and (max-width: 768px)
    margin: 20px 0 10px
  @media screen and (max-width: 500px)
    margin: 12px 0 6px