.section
  padding: 0 0 80px

.table
  box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15)
  margin: 34px 0 0
  width: 100%
  border-collapse: collapse
  thead
    color: #fff
    font-size: 16px
    font-weight: 700
    line-height: 30px
    background: #479e53
  th
    padding: 5px 10px
    &:first-child
      border-radius: 5px 0 0
    &:last-child
      border-radius: 0 5px 0 0

  tbody
    font-size: 16px
    font-weight: 400
    line-height: 30px
    th:first-child
      padding: 18px 10px 10px 34px
      border-radius: 0px
    th:last-child
      border-radius: 0px
    th
      padding: 18px
      @media screen and (max-width: 620px)
        padding: 6px
      @media screen and (max-width: 500px)
        font-size: 14px
      @media screen and (max-width: 400px)
        padding: 2px
        font-size: 10px


      div
        display: flex
        justify-content: center
        align-items: center
        width: 90px
        height: 90px
        background: linear-gradient(#ff6cbf, #ff7500)
        border-radius: 50%
        @media screen and (max-width: 620px)
          display: none

      img
        width: 80px
        height: 80px
        border-radius: 50%
        @media screen and (max-width: 620px)
          display: none
