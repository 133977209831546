.footer
  padding: 24px 0
  background: #479e53
  color: #fff
  @media screen and (max-width: 960px)
    padding: 24px 0 120px

.wrapper
  display: flex
  justify-content: space-between
  align-items: flex-start
  margin: 0 0 34px
  @media screen and (max-width: 780px)
    flex-direction: column

.copyrightContainer
  display: flex
  justify-content: space-around
  align-items: center
  @media screen and (max-width: 490px)
    flex-direction: column

.copyright
  font-size: 14px
  margin: 0 10px 0 0
  @media screen and (max-width: 490px)
    margin: 0 0 12px
.policy
  color: #ffffff
  text-decoration: none
  font-size: 14px

.column
  display: flex
  flex-direction: column
  @media screen and (max-width: 780px)
    margin: 0 0 24px

.columnLink
  text-decoration: none
  color: #fff
  margin: 0 0 18px

.columnTitle
  margin: 0 0 16px


.list
  display: flex
  flex-direction: column
  li
    margin: 0 0 8px

.navLink
  color: #ffffff
  text-decoration: none

.roboweb
  //padding: 30px 0 0
  display: flex
  justify-content: center
  align-items: center
  font-size: 14px
  @media screen and (max-width: 490px)
    text-align: center

  a
    color: #fb6e4d
    text-decoration: none
