.title
  margin: 20px 0 50px
  width: 80%
  font-size: 46px
  font-weight: 700
  @media screen and (max-width: 800px)
    font-size: 36px
    width: 90%
  @media screen and (max-width: 525px)
    font-size: 30px
    width: 100%
    text-align: center
  @media screen and (max-width: 425px)
    font-size: 24px
