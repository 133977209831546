.wrapper
  padding: 16px 0 50px
  display: flex
  justify-content: space-between
  &_reverse
    flex-direction: row-reverse
  @media screen and (max-width: 1250px)
    flex-direction: column
  img
    width: 37%
    object-fit: contain
    @media screen and (max-width: 1250px)
      width: 100%

.text
  width: 60%
  // font-size: 24px
  font-size: 18px
  @media screen and (max-width: 1250px)
    width: 100%
    margin: 0 0 18px
