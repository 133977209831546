.section
  position: fixed
  top: 0
  left: 0
  width: 100vw
  height: 100vh
  display: flex
  justify-content: center
  align-items: center
  visibility: hidden
  opacity: 0
  transition: .3s
  &_active
    visibility: visible
    opacity: 1
.overlay
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  background: rgba(0, 0, 0, 0.7)
  backdrop-filter: blur(10px)
.wrapper
  position: relative
  z-index: 2
  width: 500px
  height: 400px
  background: #479e53
  border-radius: 10px
  padding: 40px 20px
  display: flex
  flex-direction: column
  //justify-content: center
  align-items: center
  @media screen and (max-width: 600px)
    width: 100%
    padding: 20px
.title
  margin: 16px 0 40px
  width: 80%
  text-align: center
  font-size: 24px
  color: #ffffff
  span
    color: #ff9d02
  @media screen and (max-width: 500px)
    //margin: 80px 0 20px
    font-size: 20px
  @media screen and (max-width: 380px)
    //margin: 100px 0 20px
    font-size: 16px
.form
  width: 80%
  display: flex
  flex-direction: column
  input,
  textarea
    color: #fff
    padding: 10px
    border: 2px solid #fff
    border-radius: 10px
    background: transparent
    margin: 0 0 20px
    &:last-child
      margin: 0
    &::placeholder
      color: #fff

  button
    padding: 10px
    border-radius: 10px
    background: #ff9d02
.closeBtn
  cursor: pointer
  position: absolute
  top: -6px
  right: -6px
  transition: .2s
  &:hover
    opacity: .9
  @media screen and (max-width: 600px)
    top: 16px
    right: 10px