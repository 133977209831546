.navigation
  width: 50%

.list
  width: 100%
  display: flex
  justify-content: center
  align-items: center
  @media screen and (max-width: 960px)
    display: none

.listItem
  margin: 0 18px 0 0
  &:last-child
    margin: 0

.navLink
  text-decoration: none
  color: #dafffd
  white-space: nowrap
  text-transform: uppercase
