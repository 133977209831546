.section
  position: relative
  padding: 40px 0 60px
  background-image: url('../../images/main-bg/5.jpg')
  background-repeat: no-repeat
  background-position: center
  background-size: cover
  color: #fff
  &:after
    content: ''
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    background: rgba(0, 0, 0, 0.6)
    z-index: 0

.wrapper
  position: relative
  z-index: 1
  display: flex
  flex-direction: column
  align-items: center

.title
  font-size: 26px
  line-height: 33px
  span
    color: #ff9d02
  @media screen and (max-width: 768px)
    text-align: center
  @media screen and (max-width: 425px)
    font-size: 24px

.form
  margin: 54px 0 0
  width: 100%
  display: grid
  grid-gap: 16px
  grid-template-columns: repeat(3, 1fr)
  @media screen and (max-width: 1120px)
    grid-template-columns: repeat(1, 1fr)

.input
  height: 60px
  outline: none
  background: transparent
  border: 2px solid #fff
  border-radius: 10px
  color: #fff
  font-size: 19px
  font-weight: 700
  padding: 0 30px
  &::placeholder
    color: #fff
    font-size: 18px
    font-weight: 600
  @media screen and (max-width: 425px)
    padding: 0 10px

.text
  margin: 24px 0 0
  font-size: 20px
  @media screen and (max-width: 768px)
    text-align: center
