.section
  padding: 80px 0
  background: #479e53

.wrapper
  margin: 76px 0 0
  display: flex
  grid-gap: 24px
  align-items: center

.img
  width: 35%
  height: 290px
  object-fit: contain
  animation: truck 3.5s linear infinite
  @media screen and (max-width: 768px)
    display: none

.colRight
  width: 65%
  display: grid
  grid-gap: 22px
  grid-template-columns: repeat(2, 1fr)
  @media screen and (max-width: 768px)
    width: 100%
  @media screen and (max-width: 530px)
    grid-template-columns: repeat(1, 1fr)

@keyframes truck
  0%
    transform: translateY(-0px)
  25%
    transform: translateY(-20px)
  50%
    transform: translateY(0)
  75%
    transform: translateY(20px)
  100%
    transform: translateY(0)
