.section
  padding: 20px 0

.title
  margin: 0 0 30px

.list
  margin: 0 0 24px
  padding: 0 0 0 30px
  list-style-type: circle
  li
    margin: 0 0 8px
