.section
  margin: 0 auto
  padding: 100px 0
  color: #fff
  background: #9b9b9b
  display: flex
  flex-direction: column
  align-items: center
  height: auto

.subtitle
  text-align: center
  margin: 18px 0 16px
  font-size: 16px
  font-weight: 400
  max-width: 750px
  @media screen and (max-width: 760px)
    width: 95%
  @media screen and (max-width: 425px)
    margin: 18px 0 50px
    font-size: 12px

.actions
  margin: 26px 0 0
  position: relative
  height: auto
  display: flex
  flex-direction: column
  justify-content: flex-start
  

.action
  position: relative
  //width: 300px
  min-height: 50px
  display: flex
  text-align: left
  align-items: center
  gap: 10px
  &_pos_top
    align-items: flex-start
    @media screen and (max-width: 690px)
      align-items: center
  @media screen and (max-width: 690px)
    justify-content: flex-start
    align-items: center
    margin: 0 0 50px
  @media screen and (max-width: 425px)
    padding: 0 0 0 70px

.circle
  position: relative
  z-index: 1
  width: 40px
  aspect-ratio: 1/1
  border: 3px solid #fff
  border-radius: 50%
  background: #479e53
  display: flex
  justify-content: center
  align-items: center

.actionText
  font-size: 16px
  text-align: center
  @media screen and (max-width: 690px)
    text-align: left
  @media screen and (max-width: 425px)
    font-size: 12px



.verticalLine
  position: absolute
  bottom: 55px
  left: 50%
  width: 1px
  height: 70px
  border-left: 1px dashed hsla(0, 0%, 100%, .6)
  &:after
    content: ''
    width: 5px
    height: 5px
    position: absolute
    bottom: -7px
    left: -3px
    background: #c9c8c8
    border-radius: 50%
  &_pos_top
    transform: rotate(180deg)
    top: 55px
  @media screen and (max-width: 690px)
    display: none

.svgIcon
  position: absolute
  left: calc( 50% - 12px )
  top: -32px
  &_pos_top
    top: 40px
  @media screen and (max-width: 690px)
    display: none

