.section
  padding: 80px 0 0

.wrapper
  // margin: 0 0 80px
  display: flex
  justify-content: space-between
  //@media screen and (max-width: 900px)
  @media screen and (max-width: 1440px)
    flex-direction: column
    align-items: center
    margin: 0 0 50px

.textContainer
  width: 60%
  @media screen and (max-width: 1440px)
    width: 100%

.text
  margin: 0 0 36px
  width: 60%
  color: #7a7a7a
  font-size: 18px
  font-weight: 400
  @media screen and (max-width: 900px)
    width: 100%
    margin: 30px 0 0
  @media screen and (max-width: 768px)
    text-align: center
  @media screen and (max-width: 425px)
    font-size: 14px
  &_wide
    width: 100%

.img
  width: 35%
  @media screen and (max-width: 768px)
    width: 100%
  //height: 700px
  object-fit: contain
