.wrapper
  display: flex
  justify-content: space-between
  margin: 0 0 36px

.title
  margin: 12px 0 14px
  font-size: 14px
  color: #92959c
  &:first-child
    margin: 0 0 14px

.text,
.link,
.mapLink,
.fileLink
  text-decoration: none
  width: 80%
  font-size: 16px
  font-weight: 600
  line-height: 22px
  color: #3f464d
  transition: color .2s
  &:hover
    color: black

.link
  text-decoration: none

.mapLink
  margin: 0 12px 0 0
  text-decoration: none
  color: #fb6d4d
  transition: opacity .2s
  &:last-child
    margin: 0
  &:hover
    color: #fb6d4d
    opacity: .5
