.card
  color: #fff
  width: 100%
  //height: 200px
  @media screen and (max-width: 530px)
    text-align: center

.title
  margin: 0 0 6px
  font-size: 16px
  font-weight: 700

.text
  margin: 0 0 16px
  font-size: 14px
  line-height: 25px
