.fixedMenu
  position: fixed
  left: 0
  bottom: -100px
  z-index: 2
  width: 100vw
  height: 70px
  background: #479e53
  border-top-left-radius: 14px
  border-top-right-radius: 14px
  display: flex
  justify-content: space-around
  align-items: center
  transition: .3s
  @media screen and (max-width: 960px)
    bottom: -1px
  &_active
    bottom: -1px

.logo
  width: 50px
  height: 50px
  border-radius: 50%
  position: absolute
  top: -25px
  left: calc( 50% - 25px )
  box-shadow: 0 0 25px 2px rgba(0, 0, 0, 0.3)
  transition: .2s
  &:hover
    transform: scale(1.1)

.link
  color: #fff
