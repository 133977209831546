.listItem
  display: flex
  margin: 0 0 14px
  @media screen and (max-width: 480px)
    flex-direction: column
    align-items: center
    margin: 0 0 24px
    //svg
    //  margin: 0


.icon
  margin: 0 16px 0 0
  @media screen and (max-width: 480px)
    margin: 0

.textContainer

.priceTitle
  font-size: 21px
  font-weight: 700
  line-height: 21px
  color: #479e53
  @media screen and (max-width: 480px)
    text-align: center
    margin: 0 0 8px


.text
  font-size: 18px
  @media screen and (max-width: 480px)
    text-align: center


.price
  border-radius: 4px
  padding: 2px 8px
  color: #fff
  background-color: #28a745
  font-weight: 700
  line-height: 1
  text-align: center
  white-space: nowrap
