.phones
  height: 100%
  display: flex
  flex-direction: column
  justify-content: space-evenly
  align-items: center
  @media screen and (max-width: 960px)
    display: none

.link
  margin: 0
  color: #dafffd
  font-size: 16px
  font-weight: 600
  text-decoration: none
  &:hover
    text-decoration: underline
