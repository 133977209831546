.section
  padding: 16px 0 80px

.wrapper
  width: 100%
  display: grid
  grid-gap: 30px
  grid-template-columns: 40% 60%
  @media screen and (max-width: 1440px)
    grid-template-columns: 50% 50%
  @media screen and (max-width: 1100px)
    grid-template-columns: repeat(1, 1fr)


.formContainer
  position: relative
  width: 100%
  min-height: 370px
  text-align: center
  color: #fff
  background: #479e53
  border-radius: 10px
  padding: 35px
  display: flex
  flex-direction: column
  justify-content: space-between
  align-items: center
  @media screen and (max-width: 1100px)
    min-height: 320px
  @media screen and (max-width: 425px)
    padding: 16px



.title
  width: 80%
  font-size: 36px
  font-weight: 400
  line-height: 33px
  margin: 0 0 36px
  @media screen and (max-width: 1100px)
    margin: 0 0 12px
  @media screen and (max-width: 768px)
    font-size: 26px
  @media screen and (max-width: 390px)
    font-size: 18px


.form
  width: 100%
  display: flex
  flex-direction: column

.input
  outline: none
  padding: 10px 14px
  background: transparent
  border: 2px solid #fff
  border-radius: 10px
  color: #fff
  font-size: 19px
  height: 50px
  margin: 0 0 10px
  &::placeholder
    color: #fff

.bestOfferContainer
  width: 100%
  display: flex
  flex-direction: column
  justify-content: center

  h3
    font-size: 26px
    font-weight: 500
    position: relative
    padding: 0 0 15px
    color: #479e53
    margin: 0 0 24px
    &:before
      background-color: #479e53
      bottom: 0
      content: ""
      height: 2px
      left: 0
      position: absolute
      right: 0
      width: 80px


.buttonChat
  position: absolute
  top: -30px
  left: calc( 50% - 150px )
  background: #479e53
  padding: 6px 0
  width: 300px
  display: flex
  justify-content: center
  align-items: center
  border-radius: 5px 5px 0 0
  @media screen and (max-width: 425px)
    width: 250px
    left: calc( 50% - 125px )

.chatText
  color: #fff
  margin: 0 12px 0 0

.circle
  width: 10px
  height: 10px
  border-radius: 50%
  background: lawngreen

