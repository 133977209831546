.section
  padding: 80px 0 0
  @media screen and (max-width: 1340px)
    padding: 0

.img
  width: 30%
  object-fit: contain
.wrapper
  display: flex
  justify-content: space-between
  align-items: center
  @media screen and (max-width: 1340px)
    flex-direction: column

.textContent
  width: 69%
  font-size: 39px
  @media screen and (max-width: 1340px)
    text-align: center
    margin: 0 0 30px
  @media screen and (max-width: 680px)
    width: 100%

.title
  margin: 0 0 16px
  @media screen and (max-width: 680px)
    font-size: 29px
    margin: 0

.phone
  text-decoration: none
  color: #ff9d02
  font-size: 39px
  font-weight: 700
  margin: 0 0 12px
  @media screen and (max-width: 680px)
    font-size: 29px
  @media screen and (max-width: 425px)
    font-size: 22px
  &:hover
    text-decoration: underline
.text
  font-size: 24px
  font-weight: 600
  @media screen and (max-width: 425px)
    font-size: 18px
.chat
  color: #ff9d02

.whatsApp
  color: #25d366
.telegram
  color: #9200cc

.links
  margin: 18px 0 0
  display: grid
  grid-gap: 14px
  grid-template-columns: repeat(3, 1fr)
  width: 100%
  @media screen and (max-width: 680px)
    grid-template-columns: repeat(1, 1fr)

.link
  cursor: pointer
  text-align: center
  text-decoration: none
  padding: 6px 10px
  color: #fff
  border-radius: 6px
  @media screen and (max-width: 1315px)
    font-size: 18px
  &_bg_orange
    background: #ff9d02
  &_bg_green
    background: #25d366
  &_bg_blue
    background: #9200cc

