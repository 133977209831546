.img
  width: 35%
  @media screen and (max-width: 1440px)
    width: 50%
    object-fit: contain
  @media screen and (max-width: 1024px)
    width: 100%
    object-fit: contain

.images
  display: flex
  gap: 10px

  @media screen and (max-width: 1024px)
    flex-direction: column
