.section
  padding: 80px 0 200px

.wrapper
  padding: 40px 0
  width: 100%
  //height: 400px
  &:before
    border: 6px solid #ff9d02
    border-bottom: 0
    content: ""
    height: 30px
    left: 0
    margin: 0 auto
    position: absolute
    right: 0
    top: -30px
    width: 91%
    z-index: -1
  &:after
    border: 6px solid #ff9d02
    border-top:  0
    content: ""
    height: 30px
    left: 0
    margin: 0 auto
    position: absolute
    right: 0
    bottom: -30px
    width: 91%
    z-index: -1

