.section
  padding: 100px 0 50px
  background: #9b9b9b

.list
  display: grid
  grid-gap: 24px
  grid-template-columns: repeat(3, 1fr)
  @media screen and (max-width: 1200px)
    grid-template-columns: repeat(2, 1fr)
  @media screen and (max-width: 850px)
    grid-template-columns: repeat(1, 1fr)
